import React from 'react'
import styles from './styles.module.css'

const SimpleInkBadger = () => {
  return (
    // Enforcing ltr, so when a website has rtl enabled it doesn't screw the badge
    <div dir='ltr'>
      <a
        href='https://thesimple.ink/?utm_source=badger'
        target='_blank'
        className={styles.badgerLink}
      >
        <img
          src='https://assets.website-files.com/60e97131b9b8457f2608efb0/6102b9b242e6c27fc26bc725_SI%20Icon%20Black.svg'
          alt='Built on Simple.ink'
          className={styles.badgerImage}
        />
        <span className={styles.badgerText}>Built on Simple.ink</span>
      </a>
    </div>
  )
}

export default SimpleInkBadger
