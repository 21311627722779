// import ky from 'ky'
import fetch from 'isomorphic-unfetch'
import pMemoize from 'p-memoize'
import ExpiryMap from 'expiry-map'

import * as types from './types'

export const apiBaseUrl = `/api`

export const api = {
  searchNotion: `${apiBaseUrl}/search-notion`
}

export const searchNotion = (siteId: string) =>
  pMemoize(searchNotionImpl.bind(null, siteId), {
    cacheKey: (args) => args[0]?.query,
    cache: new ExpiryMap(10000)
  }) as any

async function searchNotionImpl(
  siteId: string,
  params: types.SearchParams
): Promise<types.SearchResults> {
  // Use promise all to parallel req searchnotion and pages-API
  // TODO (to improve): cache results from getProtectedPagesData-API
  return Promise.all([
    searchNotionAPI(params),
    getProtectedPagesData(siteId)
  ]).then(function (result) {
    const searchResult = result[0]
    const protectedPages = result[1]

    const filteredData = searchResult.results.filter(
      (page) => !protectedPages.find((p) => p.page_id === page.id)
    )

    searchResult.results = filteredData
    return searchResult
  })
}

async function searchNotionAPI(
  params: types.SearchParams
): Promise<types.SearchResults> {
  return fetch(api.searchNotion, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'content-type': 'application/json'
    }
  })
    .then((res) => {
      if (res.ok) {
        return res
      }

      // convert non-2xx HTTP responses into errors
      const error: any = new Error(res.statusText)
      error.response = res
      return Promise.reject(error)
    })
    .then(function (res) {
      return res.json()
    })
}

// TOdo: cache this request
// this is a temporary solution
let cachedProtectedPages: any = null
async function getProtectedPagesData(siteId: string): Promise<any> {
  if (cachedProtectedPages) return cachedProtectedPages

  return fetch(`https://api.thesimple.ink/site/readonly/${siteId}/pages/`)
    .then((res) => res.json())
    .then(function (data) {
      const protectedPages = (data.pages || []).filter(
        (page) => page.is_password_protected
      )
      cachedProtectedPages = protectedPages
      return protectedPages
    })
    .catch((err) => {
      // trigger deploy
      console.log('error at main API call')
      console.log(err)
      return {}
    })
}
